import React, { useState } from "react";
import ElectricBoltIcon from '@mui/icons-material/Bolt';
import { Dialog } from "../../../components/Dialog";
import AiAssistChat from "./AiAssistChat";

export const AiAssistButton: React.FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)

  const handleOpenModal = () => {
    setModalOpen(true)
    setLoad(true)
  }
  
  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      <button
        className="produttivo-btn"
        onClick={handleOpenModal}
      >
        <ElectricBoltIcon sx={{ fontSize: 24 }} />Assistente IA
      </button>

      <Dialog
        isOpen={modalOpen} onClose={handleCloseModal} isBeta={true}
        title="Assistente IA Produttivo"
      >
        <AiAssistChat loadMessages={load} closeModal={handleCloseModal} />
      </Dialog>
    </>
  )
}
