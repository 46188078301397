import React, { useState } from "react";
import ElectricBoltIcon from '@mui/icons-material/Bolt';
import { Dialog } from "./Dialog";
import AiAssistChat from "./AiAssistChat";
import axios from 'axios'

interface AiAssistProps {
  formFillID: number;
}

export const AiAssistComponent: React.FC<AiAssistProps> = ({ formFillID }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)

  const handleOpenModal = () => {
    setModalOpen(true)
    setLoad(true)
  }
  
  const handleCloseModal = () => {
    setModalOpen(false)
    if(refresh){
      legacyRefreshViewAfterUpdateFormFill()
    }
  }

  const legacyRefreshViewAfterUpdateFormFill = () => {
    const url = `/form_fills/${formFillID}.js`
    $.ajax({
      url: url,
      success: function(_data) {
      },
      error: function (_error) {
        alert("Ocorreu um erro e tela será recarregada.")
        window.location.reload()
      }
    })
  }

  return (
    <>
      <button
        className="mdl-button mdl-js-button mdl-js-ripple-effect button-primary action-btn white-default light-border"
        onClick={handleOpenModal}
      >
        <ElectricBoltIcon sx={{ fontSize: 24 }} />Assistente IA
      </button>

      <Dialog
        isOpen={modalOpen} onClose={handleCloseModal} isBeta={true}
        title="Assistente IA Produttivo"
      >
        <AiAssistChat formFillID={formFillID} refresh={setRefresh} loadMessages={load} />
      </Dialog>
    </>
  );
}
