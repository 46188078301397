import React, { useState, useRef, useEffect } from 'react'
import DOMPurify from 'dompurify'

import ElectricBoltIcon from '@mui/icons-material/Bolt'
import PersonIcon from '@mui/icons-material/Person'
import SendIcon from '@mui/icons-material/Send'
import IconButton from '@mui/material/IconButton';
import { CircularProgress, createTheme, TextField, ThemeProvider } from '@mui/material'
import axios from 'axios'

// TODO: Refactor states and logic, fat component

declare var _editingForm: { fieldSuggestions: string }

interface IChat {
  message: string
  sender: 'bot' | 'user'
  example?: string
}

interface IChatProps {
  loadMessages: boolean
  closeModal: () => void
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#01A32D'
    }
  }
})

const AiAssistChat: React.FC<IChatProps> = ({ loadMessages, closeModal }) => {
  const [chat, setChat] = useState<IChat[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userText, setUserText] = useState<string>('')
  const [isActiveInput, setIsActiveInput] = useState<boolean>(false)
  const [description, setDescription] = useState<string[]>([])
  const [activeActions, setActiveActions] = useState<boolean>(false)
  const [enableRetry, setEnableRetry] = useState<boolean>(false)
  const [enableClose, setEnableClose] = useState<boolean>(false)

  const sendMessage = ({ message, sender = 'bot', example }: any) => {
    const chat: IChat = { message, sender, example }
    setChat(c => [...c, chat])
  }

  const sendBotMessage = ({ message, example }: any) => {
    setTimeout(() => {
      sendMessage({ message, example })
      toggleInput()
    }, 1000);
  }

  const sendUserInput = () => {
    if(userText == null || userText.trim() === '')
      return

    const message: IChat = { message: userText, sender: 'user' }
    sendMessage(message)
    setDescription(d => [...d, userText])
    setUserText('')
    toggleInput()
  }

  const toggleInput = () => setIsActiveInput(!isActiveInput)

  const toggleConfirmActions = () => setActiveActions(!activeActions)

  const assistActions = () => {
    if(description.length === 0 && chat.length > 2) {
    } else if(description.length === 1) {
      const detailsMessage = "Existem itens específicos que precisam ser verificados durante o serviço e que não podem faltar nesse formulário?"
      setTimeout(() => {
        setIsLoading(false)
        sendMessage({ message: detailsMessage })
        toggleConfirmActions()
      }, 1000);
    } else if(description.length === 2) {
      handleFetchSuggestions()
    }
  }

  const handleInput = () => {
    sendMessage({ message: 'Sim, digitar campos para incluir', sender: 'user' })
    toggleConfirmActions()
    toggleInput()
  }

  const handleContinue = () => {
    sendMessage({ message: 'Não, continuar sem incluir campos', sender: 'user' })
    toggleConfirmActions()
    handleFetchSuggestions()
  }

  const handleFetchSuggestions = () => {
    const requestMessage = "Estou analisando as suas respostas para sugerir perguntas que este formulário poderia ter para atender às necessidades do seu serviço. ✅\n\nPor favor, aguarde."
    setTimeout(() => {
      setIsLoading(true)
      sendMessage({ message: requestMessage })
      fetchSuggestions()
    }, 1000);
  }

  const fetchSuggestionsCall = async () => {
    setIsLoading(true)
    await axios.post(
      '/ai_assist/forms/fields_suggestions.json', {
        description: description[0],
        details: description.length > 1 ? description[1] : null
      }
    ).then((response) => {
      if(response.data && Object.keys(response.data).length == 0) {
        const noSuggestionsMessage = 'Desculpe, não estou conseguindo criar sugestões para esse preenchimento com as respostas que você enviou.\n\nVamos, tentar novamente.'
        sendMessage({message: noSuggestionsMessage})
        initialChat()
      } else {
        const successMessage = "Pronto! 🙌\nApliquei ao seu formulário as sugestões de perguntas que devem ser respondidas na realização do serviço."
        _editingForm.fieldSuggestions = JSON.stringify(response.data.suggestions)
        sendMessage({message: successMessage})
        setEnableClose(true)
      }
    })
  }

  const fetchSuggestions = async () => {
    await fetchSuggestionsCall().catch((_error) => {
      sendMessage({message: 'Desculpe. Algo deu errado na sua solicitação. 😥\nClique no botão abaixo para tentar novamente.'})
      return setEnableRetry(true)
    })
    setIsLoading(false)
    setDescription([])
  }

  const handleRetry = async () => {
    setEnableRetry(false)
    sendMessage({message: 'Tentar novamente', sender: 'user'})
    await fetchSuggestionsCall().catch((_error) => {
      const noSuggestionsMessage = 'Desculpe, não estou conseguindo criar sugestões para esse preenchimento com as respostas que você enviou.\n\nVamos, tentar novamente.'
      sendMessage({message: noSuggestionsMessage})
      initialChat()
    })
    setIsLoading(false)
    setDescription([])
  }

  const scrollRef = useRef<HTMLSpanElement | null>(null)
  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(scrollToBottom, [chat])
  useEffect(assistActions, [description])

  const initialChat = () => {
    const initialMessage = "Olá, eu sou um assistente e vou te ajudar com o seu formulário! 😀"
    setTimeout(() => {
      sendMessage({ message: initialMessage })
    }, 1000);

    setTimeout(() => {
      const actionMessage = "Qual o tipo de serviço e o objetivo deste formulário?"
      const exampleMessage = "Exemplo: “Manutenção preventiva de equipamentos de ar condicionado para garantir a qualidade do ar e segurança do ambiente”"
      sendMessage({ message: actionMessage, example: exampleMessage })
      toggleInput()
    }, 2000);
  }

  useEffect(() => {
    if(loadMessages) {
      initialChat()
    }
  }, [loadMessages])

  const primary = '#fff'

  return (
    <div className='aiAssist'>
      <div className='body'>
        {chat.map((c, index) => {
          return (
            <div className="message" key={index}>
              <div className={`message__box message__box--${c.sender}`}>
                <i className={`message__avatar message__avatar--${c.sender}`}>
                  {c.sender === 'bot'? <ElectricBoltIcon /> : <PersonIcon /> }
                </i>
                <div className="message__text-box">
                  <div className={`message__text message__text--${c.sender}`}>
                    <div
                      className="message__content"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(c.message)}}
                    />
                    {c.example &&
                      <>
                        <br />
                        <div
                          className="message__support"
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(c.example)}}
                        />
                      </>
                    }
                  </div>
                  {isLoading && chat.length - 1 === index &&
                    <div className="loader">
                      <CircularProgress disableShrink size={20} sx={{ color: '#01A32D' }} />
                    </div>
                  }
                </div>
              </div>
                <div className="actions">
                </div>
            </div>
          )
        })}
        <span ref={scrollRef}></span>
      </div>
      <div className='footer-actions'>
        {isActiveInput &&
          <ThemeProvider theme={theme}>
            <TextField
              id="outlined-basic"
              label="Digite sua resposta"
              variant="outlined"
              value={userText}
              multiline
              fullWidth
              size='medium'
              rows={6}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUserText(event.target.value)}
            />
            <IconButton color="primary" aria-label="send" onClick={sendUserInput}>
              <SendIcon />
            </IconButton>
          </ThemeProvider>
        }
        {activeActions &&
          <>
            <button onClick={handleInput} className="button">
              Sim, digitar campos para incluir
            </button>
            <button onClick={handleContinue} className="button button--action">
              Não, continuar sem incluir campos
            </button>
          </>
        }
        {enableRetry &&
          <>
            <button onClick={handleRetry} className="button">
              Tentar novamente
            </button>
          </>
        }
        {enableClose &&
          <>
            <button onClick={closeModal} className="button">
              Ver formulário pronto
            </button>
          </>
        }
      </div>
    </div>
  )
}

export default AiAssistChat