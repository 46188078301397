import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import DOMPurify from 'dompurify'
import uniqueId from 'lodash.uniqueid'

import ElectricBoltIcon from '@mui/icons-material/Bolt'
import PersonIcon from '@mui/icons-material/Person'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'
import BlockIcon from '@mui/icons-material/BlockOutlined'
import { CircularProgress } from '@mui/material'

// TODO: Refactor states and logic, fat component

interface IDataProps {
  formFillID: Number
  refresh: React.Dispatch<React.SetStateAction<boolean>>
  loadMessages: boolean
}

interface IChat {
  message: string
  actions: boolean
  sender: 'bot' | 'user'
  suggestionId?: number
  chatId: number
  error: boolean
  copy: boolean
}

interface IMessage {
  message: string
  actions?: boolean
  sender?: 'bot' | 'user'
  suggestionId?: number
  error?: boolean
  copy?: boolean
}

interface ISuggestion {
  id: number
  field_name: string
  original_value: string
  value: string
  attachments?: IAttachment[]
}

interface IAttachment {
  id: number
  original_value: string
  value: string
}

const AiAssistChat: React.FC<IDataProps> = ({ formFillID, refresh, loadMessages }) => {
  const actionMessage = "Clique em uma das opções abaixo para continuar. 😀"
  const [chat, setChat] = useState<IChat[]>([])
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([])
  const [checkedSuggestions, setCheckedSuggestions] = useState<ISuggestion[]>([])
  const [denySuggestions, setDenySuggestions] = useState<number[]>([])
  const [enableApply, setEnableApply] = useState<boolean>(false)
  const [lastIdsToSuggestion, setIdsToSuggestion] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [lastRefetch, setLastRefetch] = useState<Function>(() => {})
  const [isCopied, setIsCopied] = useState<boolean>()
  const [enableActions, setEnableActions] = useState<boolean>(false)
  const [reviewActions, setReviewActions] = useState<boolean>(false)
  const [applyActions, setApplyActions] = useState<boolean>(false)
  const [selectActions, setSelectActions] = useState<boolean>(false)
  const [ignoreIds, setIgnoreIds] = useState<number[]>([])

  const toggleActions = () => {
    setTimeout(() => {
      sendMessage({ message: actionMessage })
      setEnableActions(true)
    }, 1000);
  }

  const toggleApply = () => {
    if (checkedSuggestions.length === 0 && denySuggestions.length === 0)
      return setEnableApply(false)
    const allSuggestionsIsChecked = checkedSuggestions.length + denySuggestions.length === suggestions.length
    setEnableApply(allSuggestionsIsChecked)
  }

  const scrollRef = useRef<HTMLSpanElement | null>(null)
  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(scrollToBottom, [chat])
  useEffect(toggleApply, [checkedSuggestions, denySuggestions])

  useEffect(() => {
    if(loadMessages && chat.length === 0)
      setTimeout(() => {
        const initialMessage = "Olá, eu sou um assistente de texto e vou te ajudar a revisar e resumir o preenchimento dessa atividade."
        sendMessage({ message: initialMessage })
        toggleActions()
      }, 1000)
  }, [loadMessages])

  const sendMessage = ({ message, actions = false, sender = 'bot', suggestionId, error = false, copy = false }: IMessage) => {
    const chatId = parseInt(uniqueId())
    if(suggestionId)
      setIdsToSuggestion(i => [...i, chatId])
    const chat: IChat = { message, actions, sender, suggestionId, chatId, error, copy }
    setChat(c => [...c, chat])
  }

  const sendErrorMessage = () => {
    setTimeout(() => {
      sendMessage({
        message: "Desculpe. Algo deu errado na sua solicitação. 😥\nClique no botão abaixo para tentar novamente.",
        error: true
      })
      setIsLoading(false)
    }, 1000)
  }

  const checkSuggestion = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    if(checked) {
      const findSuggestion = suggestions.find(s => s.id == parseInt(value))
      if(findSuggestion) {
        setDenySuggestions(denySuggestions.filter(c => c !== parseInt(value)))
        setCheckedSuggestions([...checkedSuggestions, findSuggestion])
      }
      return
    }

    setCheckedSuggestions(checkedSuggestions.filter(c => c.id !== parseInt(value)))
  }

  const uncheckSuggestion = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target
    setDenySuggestions([...denySuggestions, parseInt(value)])
    setCheckedSuggestions(checkedSuggestions.filter(c => c.id !== parseInt(value)))
  }

  const handleAllSuggestions = () => {
    setReviewActions(false)
    sendMessage({ message: `Aceitar ${ suggestions.length > 1 ? 'todas' : '' }`, sender: 'user' })
    setCheckedSuggestions(suggestions)
    setDenySuggestions([])
    setTimeout(() => {
      sendMessage({ message: 'O preenchimento será modificado para aplicar as sugestões acima e não será possível desfazer essa ação.\nDeseja aplicar as mudanças?'})
      setApplyActions(true)
    }, 1000)
  }

  const handleApplyAll = () => {
    setIgnoreIds([...ignoreIds, ...lastIdsToSuggestion])
    setApplyActions(false)
    setIdsToSuggestion([])
    sendMessage({ message: 'Aplicar mudanças', sender: 'user' })
    fetchSuggestions()
  }

  const handleSuggestions = async() => {
    setIdsToSuggestion([])
    setApplyActions(false)
    setSelectActions(false)
    sendMessage({ message: 'Aplicar mudanças', sender: 'user' })

    if (!checkedSuggestions.length) {
      setTimeout(() => {
        sendMessage({ message: 'Ok! O seu preenchimento não foi alterado. 🙂' })
        toggleActions()
      }, 1000)
      return
    }

    fetchSuggestions()
  }

  const fetchSuggestions = () => {
    setTimeout(async () => {
      setIsLoading(true)
      sendMessage({ message: 'Aplicando as sugestões ao preenchimento...\nPor favor, aguarde.' })

      try {
        await axios.put(`/form_fills/${formFillID}.json`, {
          field_values_attributes: checkedSuggestions
        })
        setIsLoading(false)
        sendMessage({ message: 'Pronto! As sugestões selecionadas foram aplicadas ao preenchimento. 😉', actions: true })
        refresh(true)
        toggleActions()
      } catch (error) {
        setLastRefetch(() => fetchSuggestions)
        sendErrorMessage()
      }
    }, 1000)
    setCheckedSuggestions([])
    setDenySuggestions([])
  }

  const handleReview = () => {
    setEnableActions(false)
    setIdsToSuggestion([])
    sendMessage({ message: 'Revisar textos', sender: 'user' })
    fetchReview()
  }

  const fetchReview = () => {
    setTimeout(async () => {
      setIsLoading(true)
      sendMessage({ message: 'Estou revisando o preenchimento para sugerir ajustes ortográficos e melhorias na escrita...' })
      try {
        const response = await axios.get(`/ai_assist/form_fills/${formFillID}/suggestions.json`)
        const dataSuggestions: ISuggestion[]  = response.data.suggestions.filter((item: ISuggestion) => !('attachments' in item))
        setIsLoading(false)
        if (!dataSuggestions.length) {
          sendMessage({ message: 'Não encontrei sugestões de melhorias para o seu preenchimento. Bom trabalho! 😀👏', actions: true })
          return toggleActions()
        }
  
        setSuggestions(dataSuggestions)
        sendMessage({ message: 'Revisão feita! 🔍\nVeja o que podemos alterar para melhorar o seu preenchimento: ' })
        dataSuggestions.map(async (suggestion) => {
          var message = `<b>${suggestion.field_name}</b>\n`
          message += `\nTexto atual: ${suggestion.original_value}\nAlterar para: ${suggestion.value}`
          sendMessage({ message: message, actions: false, sender: 'bot', suggestionId: suggestion.id })
        })
        setReviewActions(true)
      } catch (error) {
        setLastRefetch(() => fetchReview)
        sendErrorMessage()
      }
    }, 1000)
  }

  const handleSummary = () => {
    setEnableActions(false)
    setIdsToSuggestion([])
    sendMessage({ message: 'Gerar resumo', sender: 'user' })
    fetchSummary()
  }

  const fetchSummary = () => {
    setTimeout(async () => {
      setIsLoading(true)
      const summaryMessage = 'Estou analisando o preenchimento para sugerir um texto resumido com base nas respostas dos campos...'
      sendMessage({ message: summaryMessage })

      try {
        const response = await axios.get(`/ai_assist/form_fills/${formFillID}/summary.json`)
        setIsLoading(false)
        sendMessage({ message: 'Aqui está o resumo desse preenchimento:' })
        sendMessage({ message: response.data.summary, actions: true, copy: true })
        toggleActions()
      } catch(error) {
        setLastRefetch(() => fetchSummary)
        sendErrorMessage()
      }
    }, 1000)
  }

  const handleRefetch = () => {
    sendMessage({ message: 'Tentar novamente', sender: 'user' })
    lastRefetch()
  }

  const handleBackToMenu = () => {
    setApplyActions(false)
    setSelectActions(false)
    setCheckedSuggestions([])
    setDenySuggestions([])

    setEnableApply(false)
    sendMessage({ message: 'Cancelar e voltar', sender: 'user' })
    setTimeout(() => {
      sendMessage({ message: 'Ok! O seu preenchimento não foi alterado. 🙂' })
      toggleActions()
    }, 1000)
  }

  const handleRejectSuggestions = () => {
    setReviewActions(false)
    setIgnoreIds([...ignoreIds, ...lastIdsToSuggestion])
    sendMessage({ message: `Dispensar ${ suggestions.length > 1 ? 'todas' : '' }`, sender: 'user' })
    setTimeout(() => {
      sendMessage({ message: 'Ok! O seu preenchimento não foi alterado. 🙂' })
      toggleActions()
    }, 1000)
  }

  const handleSelectSuggestions = () => {
    setReviewActions(false)
    sendMessage({ message: 'Selecionar', sender: 'user' })
    setTimeout(() => {
      sendMessage({ message: 'Clique nos botões disponíveis para decidir "Aceitar" ou "Dispensar" cada sugestão manualmente.' })
      setSelectActions(true)
    }, 1000)
  }

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }
  
  return (
    <div className='aiAssist'>
      <div className='body'>
        {chat.map((c, index) => {
          return (
            <div className="message" key={index}>
              <div className={`message__box message__box--${c.sender}`}>
                <i className={`message__avatar message__avatar--${c.sender}`}>
                  {c.sender === 'bot'? <ElectricBoltIcon /> : <PersonIcon /> }
                </i>
                <div className="message__text-box">
                  <div className={`message__text message__text--${c.sender}`}>
                    <div
                      className="message__content"
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(c.message)}}
                    />
                    {c.suggestionId && ((!ignoreIds.includes(c.chatId) && !lastIdsToSuggestion.includes(c.chatId)) || selectActions) &&
                      <div className='message__actions'>
                        <div className='message__option'>
                          <input type="radio" id={`suggestion-${index}-accept`}
                            name={`suggestion-${index}`} value={c.suggestionId}
                            onChange={checkSuggestion} disabled={!lastIdsToSuggestion.includes(c.chatId)}/>
                          <label htmlFor={`suggestion-${index}-accept`}>
                            <CheckCircleIcon /> Aceitar
                          </label>
                        </div>
                        <div className='message__option message__option--deny'>
                          <input type="radio" id={`suggestion-${index}-deny`}
                            name={`suggestion-${index}`} value={c.suggestionId}
                            onChange={uncheckSuggestion} disabled={!lastIdsToSuggestion.includes(c.chatId)} />
                          <label htmlFor={`suggestion-${index}-deny`}>
                            <BlockIcon /> Dispensar
                          </label>
                        </div>
                      </div>
                    }
                    {c.copy &&
                      <button onClick={() => copyText(c.message)}
                        className={`message__copy ${isCopied ? 'message__copy--copied' : ''}`}>
                        <ContentCopyIcon />
                        {isCopied ? 'Texto copiado' : 'Copiar texto'} 
                      </button>
                    }
                  </div>
                  {isLoading && chat.length - 1 === index &&
                    <div className="loader">
                      <CircularProgress disableShrink size={20} sx={{ color: '#01A32D' }} />
                    </div>
                    }
                </div>
              </div>
                <div className="actions">
                {c.error && chat.length - 1 === index &&
                  <button onClick={handleRefetch} className="button">
                    Tentar novamente
                  </button>
                }
                </div>
            </div>
          )
        })}
        <span ref={scrollRef}></span>
      </div>
      <div className='footer-actions'>
        {enableActions &&
          <>
            <button onClick={handleSummary} className="button">
              Gerar resumo
            </button>
            <button onClick={handleReview} className="button">
              Revisar textos
            </button>
          </>
        }
        {reviewActions &&
          <>
            <button onClick={handleAllSuggestions} className="button">
              <CheckCircleIcon /> {`Aceitar ${ suggestions.length > 1 ? 'todas' : '' }`}
            </button>
            {suggestions.length > 1 &&
              <button onClick={handleSelectSuggestions} className="button button--action">
                <span className="material-symbols-outlined">arrow_selector_tool</span> Selecionar
              </button>
            }
            <button onClick={handleRejectSuggestions} className="button button--danger">
              <BlockIcon /> {`Dispensar ${ suggestions.length > 1 ? 'todas' : '' }`}
            </button>
          </>
        }
        {applyActions &&
          <>
            <button onClick={handleBackToMenu} className="button button--action">
              Cancelar e voltar
            </button>
            <button onClick={handleApplyAll} className="button">
              Aplicar mudanças
            </button>
          </>
        }
        {selectActions &&
          <>
            <button onClick={handleBackToMenu} className="button button--action">
              Cancelar e voltar
            </button>
            <button onClick={handleSuggestions} className="button"
              disabled={!enableApply}
            >
              Aplicar mudanças
            </button>
          </>
        }
      </div>
    </div>
  )
}
export default AiAssistChat

